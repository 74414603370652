import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { colors } from '../theme'
import Link from '../components/Link'

const TagsList = ({ title, tags }) => (
  <StyledTagsList>
    <p className="title">{title}</p>
    <div className="tags">
      {tags &&
        tags.map((tag, index) => (
          <div key={index}>
            {tag.link ? (
              <Link to={tag.link}>
                <div className="tag">{tag.title}</div>
              </Link>
            ) : (
              <div className="tag">{tag.title}</div>
            )}
          </div>
        ))}
    </div>
  </StyledTagsList>
)

const StyledTagsList = styled.section`
  .title {
    font-family: Centra Bold;
    color: ${colors.brand.normal};
    font-size: 20px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;

    > div:not(:last-child) {
      margin-right: 10px;
    }

    .tag {
      height: 35px;
      background-color: #f5f5f5;
      border-radius: 18px;
      font-size: 17px;
      color: #383838;
      padding: 5px 40px;
      margin-top: 8px;
      white-space: nowrap;
    }

    a .tag:hover {
      box-shadow: 0 4px 6px 0 rgba(56, 56, 56, 0.11);
    }
  }
`

export default TagsList
export { StyledTagsList }

export const TagFragment = graphql`
  fragment TagFragment on ContentfulBanner {
    title
    link
  }
`
